import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/automotive-software-development/Anchors'
import AutomotiveDevBenefits from '~/mock/services/automotive-software-development/AutomotiveDevBenefits'
import AutomotiveDevExpertise from '~/mock/services/automotive-software-development/AutomotiveDevExpertise'
import AutomotiveDevFAQ from '~/mock/services/automotive-software-development/AutomotiveDevFAQ'
import AutomotiveDevIndustries from '~/mock/services/automotive-software-development/AutomotiveDevIndustries'
import AutomotiveDevProcess from '~/mock/services/automotive-software-development/AutomotiveDevProcess'
import AutomotiveDevRequirements from '~/mock/services/automotive-software-development/AutomotiveDevRequirements'
import AutomotiveDevServices from '~/mock/services/automotive-software-development/AutomotiveDevServices'
import AutomotiveWhyHire from '~/mock/services/automotive-software-development/AutomotiveWhyHire'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import useAutomotiveSoftwareDevelopmentStaticQuery from '~/views/Services/AutomotiveSoftwareDevelopment/useAutomotiveSoftwareDevelopmentStaticQuery'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'

import * as containerStyles from './AutomotiveSoftwareDevelopment.module.scss'

const clientName = 'CEO'

const AutomotiveSoftwareDevelopment = () => {
  const query = useAutomotiveSoftwareDevelopmentStaticQuery()
  const portfolioAfricar = getImage(fileToImageLikeData(query.portfolioAfricar))
  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const clientRef = getImage(fileToImageLikeData(query.clientRef))

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Automotive Software"
        title="Development Company"
        description="We are here to put your brand in the digital fast lane. Let’s guide the road to success together - fasten your seatbelt for a smooth ride into the future!"
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Automotive Software Development Company"
        breadcrumbCurrentPath="/services/automotive-software-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.automotiveServicesSection}
      >
        <HeadSection
          position="center"
          title="Extensive automotive software development services we offer"
          description="Welcome to the hub of innovation – our software development services. We offer a suite of solutions that transform your digital landscape. Get ready for a ride where technology and automotive excellence come together, creating a customized path for your brand’s success online."
          titleLarge
          descriptionLarge
        />
        <Service3DIconCard dataContent={AutomotiveDevServices} />
      </section>

      <section
        id="industries"
        className={containerStyles.automotiveIndustriesSection}
      >
        <HeadSection
          position="center"
          title="Codica’s services are trusted by businesses of any size"
          description={
            <>
              At Codica, our reputation for excellence in
              <Link
                to="/blog/how-to-build-a-car-rental-website/"
                className="mx5"
                key="keyLinkDescription"
              >
                automotive software development services
              </Link>
              spans businesses of all sizes. Our commitment is to deliver
              tailored services that align with your unique vision, ensuring a
              digital presence that accelerates success across the entire
              spectrum of business scale.
            </>
          }
          descriptionLarge
        />

        <ServicesIndustriesCard dataContent={AutomotiveDevIndustries} />
      </section>

      <CallToAction
        title="Search for a reliable automotive software development company?"
        subtitle="Let’s take your automotive experience to the next level!"
        classNameForGA="servicesCTA_1"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section
        id="process"
        className={containerStyles.automotiveProcessSection}
      >
        <HeadSection
          position="center"
          title="Comprehensive software development automotive process"
          description="At Codica, our specialists provide robust and scalable software development for the automotive industry, allowing manufacturers to stay ahead of the curve and customers to buy, sell, or rent vehicles easily."
          descriptionLarge
        />
        <ServicesProcess dataContent={AutomotiveDevProcess} />
        <SubtractBlock isTop />
      </section>

      <section
        id="benefits"
        className={containerStyles.automotiveBenefitsSection}
      >
        <HeadSection
          position="center"
          title="Crucial benefits of our automotive software development solutions"
          description="Welcome to the road of crucial advantages with Codica’s automotive software development solutions! Here’s why our services stand out."
          titleLarge
          descriptionLarge
        />

        <ServicesIconSmallCard dataContent={AutomotiveDevBenefits} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.automotiveTechStackSection}
      >
        <HeadSection
          position="center"
          title="Advanced software development automotive tech stack to create custom solutions"
          description="We use a cutting-edge tech stack to deliver custom solutions tailored just for you. At Codica, our tech stack is not just about coding; it’s about creating attractive, reliable, and innovative automotive software development solutions that drive your business into the future. So, let’s build something unique together!"
          titleLarge
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section
        id="requirements"
        className={containerStyles.automotiveRequirementsSection}
      >
        <HeadSection
          position="center"
          title="Essential requirements for top-notch automotive software"
          description="You can step into automotive industry software development, where innovation meets simplicity. We focus on the specific requirements to ensure your digital journey is smooth and successful."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard
          isBorder
          dataContent={AutomotiveDevRequirements}
        />
      </section>

      <CallToAction
        title="Looking for advanced automotive software development services?"
        subtitle="Let’s transform your automotive future together!"
        classNameForGA="servicesCTA_2"
        linkName="Contact us"
        link="/contacts/"
      />

      <section className={containerStyles.automotiveCasesSection} id="cases">
        <HeadSection position="center" title="Our exciting case study" />
        <DiscoverWorksItem
          gradient="gradientAfricar"
          image={portfolioAfricar}
          header="Multi-vendor vehicle marketplace"
          title="This is the first vehicle marketplace platform in Africa, and it consists of 90+ independent marketplaces, fully localized for each country. The marketplace was initially designed, developed, and optimized with focus on mobile experience."
          resultsLeftTitle="90+"
          resultsLeft="online marketplaces operating as part of the platform"
          resultsRightTitle="430%"
          resultsRight="traffic growth for the past 2 years thanks to SEO-optimised website"
          link="multi-vendor-vehicle-marketplace"
          review="“We are delighted to collaborate with Codica. In conversation with the Codica team, we decided to choose Ruby on Rails as a core technology for marketplace development, which turned out to be the best decision for our needs, and together we quickly launched an MVP for our automotive marketplace.”"
          clientPhoto={
            <StaticImage
              src="../../../assets/images/avatars/AvatarDraft.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName={clientName}
          clientPosition="Marketplace Investment Company, Australia"
        />
      </section>

      <section className={containerStyles.automotiveWhyHireSection}>
        <HeadSection
          position="center"
          title="Why hire Codica automotive software development company"
          description="Codica is one of the top automotive software development companies that can drive your online presence to new heights. Here’s why you should choose us for your automotive software development."
          descriptionLarge
        />
        <CompanyWhyHireBlock dataContent={AutomotiveWhyHire} />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.automotiveProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <section className={containerStyles.automotiveDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Our reliable background in software development"
          description="Choosing Codica means selecting a reliable automotive software development company invested in your success."
        />
        <ServicesDevExpertiseCompany dataContent={AutomotiveDevExpertise} />
      </section>

      <section className={containerStyles.automotiveSkilledSection}>
        <HeadSection
          position="center"
          title="We are skilled and trustworthy automotive software development company"
          description="With a wealth of automotive software development experience, Codica is a trusted partner for delivering robust and practical solutions."
          titleLarge
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'At Codica, we bring together technical prowess, adherence to industry standards, and the DevOps methodologies to ensure the success of your automotive software development project. Employing our extensive skills and in-depth knowledge, Codica specializes in developing automotive software that is not only reliable and secure but also tailored to your specific needs.',
            },
            {
              text: 'Our solutions are designed to be fast-loading, scalable on-demand, and easily integrated with third-party services. Whether you require single-tenant or multi-tenant platforms, Codica excels in creating and maintaining automotive software with minimal downtime.',
            },
          ]}
        />
      </section>

      <section className={containerStyles.automotiveClientQuotesSection}>
        <HeadSection
          position="center"
          title="Our clients say"
          description="At the heart of our custom software development services are the experiences and successes shared by those who’ve journeyed with us. Their words reflect the commitment, innovation, and satisfaction that define our collaborative efforts."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Leon Zinger"
            location="Israel"
            position="Founder at RefDental"
            image={clientRef}
            quote={[
              {
                text: '“What we like most about working with Codica is their deep understanding of our business needs. They quickly grasped our initial idea and delivered our Minimum Viable Product within the specified time and budget. Thanks to this, we could get user feedback fast”',
              },
            ]}
            link="dental-imaging-management-system"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s deliver an advanced automotive software solution for your project!"
          widthTitle="900px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our software developers will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={AutomotiveDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default AutomotiveSoftwareDevelopment
