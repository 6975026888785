// extracted by mini-css-extract-plugin
export var automotiveBenefitsSection = "Q_h7";
export var automotiveCasesSection = "Q_jc";
export var automotiveClientQuotesSection = "Q_jj";
export var automotiveDevExpertiseSection = "Q_jg";
export var automotiveIndustriesSection = "Q_h6";
export var automotiveProcessSection = "Q_h8";
export var automotiveProjLogoSection = "Q_jf";
export var automotiveRequirementsSection = "Q_jb";
export var automotiveServicesSection = "Q_h5";
export var automotiveSkilledSection = "Q_jh";
export var automotiveTechStackSection = "Q_h9";
export var automotiveWhyHireSection = "Q_jd";